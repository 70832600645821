<template>
	<lee-page code="survey" titleClass="margin-top-70 light f70" bg-img="/images/bg_head_9.jpg" title="奖项申报" height="250" :bread="[{title:'奖项申报',url:'/survey'},{title:'奖项信息'}]">
		<div class="bg-img-11"  style="background-color:#f8f9fa">
			<div class="wrap860 padding-top-bottom-100">
				<div class="cl"></div>
				<div class="bg-light box-shadow border-radius-5 rel" style="padding:30px;">
					<template v-if="info">
						<div class="green-line">
							<div class="cl f34">
								<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
								{{ info.title }}
							</div>
						</div>
						<div class="f16 dark-1" v-html="info.html_content"></div>
						<div class="cl dark-2 f20">
							<img src="/images/icon/icon_62.png" height="20"/>
							项目简介
						</div>
						<div v-html="info.clean_des" class="f-thin dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							申报要求
						</div>
						<div v-html="info.fanwei" class="f-thin dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							项目设置
						</div>
						<div v-html="info.tiaojian" class="f-thin dark-1 padding-top-bottom"></div>
						<div class="cl margin-top-20" v-if="info.files && info.files.length > 0">
							<div class="z text-right dark-2" style="width: 150px;">附件：</div>
							<div class="z" style="white-space: break-spaces;width: 890px;">
								<div v-for="(item,index) in info.files" style="margin-bottom:10px;display: block;">
									<a :href="$utils.formatImagePath(item.url)" target="_blank" style="display:inline-block;border-radius: 15px;height: 30px;background-color: #3a8ee6;color:#fff !important;font-size: 14px;padding:5px 10px;">{{item.name}}</a>
								</div>
							</div>
						</div>
						<template v-if="info.state === 2">
							<div class="cl dark-2 f20 margin-top-20">
								<img src="/images/icon/icon_62.png" height="20"/>
								申报结果
							</div>
							<div class="margin-top" v-if="companyList.length > 0">
								<table class="lee-table margin-auto" style="width:100%;">
									<thead>
									<td width="150">排名</td>
									<td>企业名称</td>
									<td width="150">省份</td>
									<td width="50"></td>
									</thead>
									<tr v-for="(item,index) in companyList">
										<td>{{ item.arank }}</td>
										<td>{{ item.company.title }}</td>
										<td>{{ item.company && item.company.province_name ? item.company.province_name : '-' }}</td>
										<td><el-link type="success" @click="$router.push({path:'/company/detail/'+item.company_id})">查看</el-link></td>
									</tr>
								</table>
							</div>
						</template>
						<div style="background-color: #f6f6f6;" class="padding-30 margin-top-20 text-center" v-if="info.state === 1 || info.state === 2">
							<template v-if="info.state === 2">报名已结束，</template>
							<template v-if="user">
								<el-button class="" :type="info && info.state === 1 ? 'success' : 'primary'" @click="toJoin">{{getJoinText}}</el-button>
							</template>
							<login-button size="normal" :type="info && info.state === 1 ? 'success' : 'primary'" :text="getJoinText" v-else></login-button>
						</div>
						<div style="background-color: #f6f6f6;" class="f18 padding-30 margin-top-20 text-center" v-if="info.state === 0">
							报名还未开始，请耐心等待<login-button size="normal" v-if="!user"></login-button>
						</div>
						<span v-if="info.state === 1" class="bg-green light f16" style="position: absolute;right:90px;top:0;border-radius:0 0 3px 3px;padding:5px 10px;">进行中</span>
						<span v-if="info.state === 2" class="f16" style="background-color:#eee;color:#777;position: absolute;right:90px;top:0;border-radius:0 0 3px 3px;padding:5px 10px;">已结束</span>
						<span v-if="info.state === 0" class="f16" style="background-color:#ffb700;color:#fff;position: absolute;right:90px;top:0;border-radius:0 0 3px 3px;padding:5px 10px;">未开始</span>
					</template>
				</div>
			</div>
		</div>
		<div style="width: 102px;position: fixed;right:20px;top:300px;border:1px solid #eee;" class="bg-light" v-if="info && info.qrcode">
			<img :src="info.qrcode" style="width: 100px;"/>
			<div class="text-center f14" style="line-height: 30px;">扫码分享</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "done",
	data() {
		return {
			id:'',
			info:null,
			list:[],
			companyList:[],
			user:null
		}
	},
	created() {
		this.id = this.$route.params.id ? this.$route.params.id : ''
	},
	mounted() {
		this.user = this.$user.getUser();
		if(this.id){
			this.getDetail();
		}
	},
	computed:{
		getJoinText(){
			if(this.user) {
				if (this.info) {
					if (this.info.can_edit === 1) {
						return '修改报名信息'
					}else if(this.info.can_edit === -1){
						return '立即报名'
					}else {
						return '查看报名信息'
					}
				} else {
					return '立即报名'
				}
			}else{
				return '请先登录'
			}
		}
	},
	methods: {
		toInfo(item){
			let _this = this;

			this.$router.push({path:'/survey/info/'+item.id});
		},
		toJoin(){
			this.$router.push({path:'/survey/join/form',query:{id:this.info.id}})
		},
		toStart(){
			let _this = this;

			if(this.info.state === 0){
				_this.$alert('此奖项申报未开启', '提示', {
					confirmButtonText: '知道了',
					showClose:false,
					callback: action => {}
				});
				return;
			}

			if(this.info.state === 2){
				_this.$alert('此奖项申报已结束', '提示', {
					confirmButtonText: '知道了',
					showClose:false,
					callback: action => {}
				});
				return;
			}

			this.$router.push({path:'/survey/detail/'+this.id});
		},
		goBack(){
			this.$router.back();
		},
		getList(){
			let _this = this;

			this.$http.post({
				url:'/SurveyTitle/listAll',
				data:{pid:this.id,orderBy:'field(state,1,2,0)',sortBy:'asc'},
				success(res){
					_this.list = res;
				}
			})
		},
		getCompany() {
			let _this = this;
			this.$http.post({
				url: '/SurveyCompanyChoose/listAll',
				data: {survey_id:this.id, orderBy:'arank',sortBy:'asc'},
				success(res) {
					_this.companyList = res;
				}
			});
		},
		getDetail(){
			let _this = this;
			this.$http.post({
				url:'/SurveyTitle/detail',
				data:{id:this.id},
				success(res){
					_this.info = res;
					if(res.has_child === 1){
						_this.getList();
					}
					if(res.state === 2){
						_this.getCompany();
					}
				},
				fail(err){
					if(err.code === -100){
						_this.$alert(err.msg, '提示', {
							confirmButtonText: '立即返回',
							showClose:false,
							callback: action => {
								_this.$router.back();
							}
						});
					}else{
						_this.$message.error(err.msg);
					}
				}
			})
		}
	}
}
</script>
