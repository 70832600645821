<template>
  <lee-page code="survey" titleClass="margin-top-70 light f70" title-pos="left" bg-img="/images/bg_head_9.jpg" :title="info.title" height="250" :bread="[{title:'奖项申报',url:'/survey'},{title:info.title}]" v-if="info">
    <div class="bg-img-2"  style="background-color:#f8f9fa">
      <div class="wrap860 padding-top-bottom-100 ">
				<div class="margin-top-20">
					<el-steps :active="active">
						<el-step title="填写基础信息"></el-step>
						<el-step title="填写项目信息"></el-step>
						<el-step title="完成"></el-step>
					</el-steps>
				</div>
        <div class="cl"></div>
        <template v-if="$user.getToken()">
<!--          <div class="bg-light padding-20 box-shadow margin-bottom-20" v-if="info.des_html" v-html="info.des_html"></div>-->
<!--          <div class="bg-light padding-20 box-shadow margin-top" v-if="info.html_content">-->
<!--						<div class="dark-2">奖项说明：</div>-->
<!--						<div style="white-space: break-spaces;" v-html="info.html_content">{{info.html_content}}</div>-->
<!--					</div>-->
          <el-form :model="formData" ref="form" class="margin-top-20" :disabled="[1,2].indexOf(formData.check_state) >= 0">
						<div class="bg-light padding-20 box-shadow margin-bottom-20">
							<el-form-item label-width="80px" label="审核状态" prop="state" style="margin-bottom: 0;">
								<el-tag size="small" type="danger" effect="dark" v-if="formData.check_state === 3">审核不通过</el-tag>
								<el-tag size="small" type="success" effect="dark" v-if="formData.check_state === 2">审核通过</el-tag>
								<el-tag size="small" type="warning" effect="dark" v-if="formData.check_state === 1">待审核</el-tag>
								<el-tag size="small" type="info" effect="dark" v-if="formData.check_state === 0">待提交</el-tag>
							</el-form-item>
							<el-form-item label-width="80px" label="原因" prop="reason" v-if="formData && formData.reason" style="margin-bottom: 0;">
								{{formData.reason}}
							</el-form-item>
						</div>
<!--						{{formData}}-->
<!--						<div>{{list}}</div>-->
            <div class="bg-light padding-20 box-shadow" style="margin-bottom: 20px;" v-for="(item,index) in formData.content" v-if="jumpList.indexOf(item.id) >= 0 || item.is_jump === 0" ref="collist">
<!--							{{item}}-->
<!--							{{item.q_required === 1 ? '(必答) ' : ''}}-->
              {{index + 1}}、{{item.title}}
              <el-form-item :prop="'content.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请选择答案'}]" v-if="item.type === 'radio'" :show-message="true">
                <el-radio-group v-model="formData.content[index].value[0]" style="margin-top:10px;width:100%;" @change="setCache">
                  <div style="border:1px solid #eee;margin-bottom:10px;" v-for="(st,si) in item.input">
                    <el-radio :label="si" style="display: block;padding:10px;" @change="(e)=>onChangeRadio(e,item,st,index,si)">{{st.title}}</el-radio>
                    <el-form-item style="margin:0 10px 10px;" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+st.id+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="formData.content[index].value[0] === st.id && st.is_input === 1" :show-message="false">
                      <el-input v-model="formData.content[index].input[st.id].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit></el-input>
                    </el-form-item>
                  </div>
                </el-radio-group>
              </el-form-item>
							<el-form-item :prop="'content.'+index+'.value'" v-else-if="item.type === 'line-input'" :show-message="true">
								<div style="margin: 10px 0;" v-for="(st,si) in item.input">
									<template v-for="(st2,si2) in st.value">
										<template v-if="st2.type === 'text'">{{st2.value}}</template>
										<template v-if="st2.type === 'input'">
											<el-form-item :show-message="false" label-width="0px" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+si+'.value.'+si2+'.value'" :rules="[{required:true,message:'请输入内容'}]" style="display: inline-block;">
												<el-input size="mini" v-model="formData.content[index].input[si].value[si2].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit @input="setCache" style="width: 100px;margin:0 10px;"></el-input>
											</el-form-item>
										</template>
									</template>
								</div>
<!--								<div v-for="(st,si) in item.child">-->
<!--									<template v-for="(st2,si2) in st.content">-->
<!--										<template v-if="st2.type === 'text'">-->
<!--											<span>{{st2.value}}</span>-->
<!--										</template>-->
<!--										<template v-if="st2.type === 'input'">-->
<!--											<el-form-item :prop="'content.'+index+'."-->
<!--											<el-input size="mini" v-model="st2.value" style="width: 100px;"></el-input>-->
<!--										</template>-->
<!--									</template>-->
<!--								</div>-->
							</el-form-item>
              <el-form-item :prop="'content.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请选择答案'}]" v-else-if="item.type === 'multi'" :show-message="true">
                <el-checkbox-group v-model="formData.content[index].value" style="margin-top:10px;width:100%;" @change="setCache">
                  <div style="border:1px solid #eee;margin-bottom:10px;" v-for="(st,si) in item.input">
                    <el-checkbox :label="si" style="display: block;line-height: 1 !important;padding:10px;" @change="(e)=>onChangeRadio(e,item,st,index,si)">{{st.title}}</el-checkbox>
                    <el-form-item style="margin:0 10px 10px;" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+si+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="formData.content[index].value.indexOf(si) >= 0 && st.is_input === 1" :show-message="false">
                      <el-input v-model="formData.content[index].input[si].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit></el-input>
                    </el-form-item>
                  </div>
                </el-checkbox-group>
              </el-form-item>
							<el-form-item :prop="'content.'+index+'.value'" v-else-if="item.type === 'multi-input'" :show-message="true">
								<template style="border:1px solid #eee;margin-bottom:10px;" v-for="(st,si) in item.input">
									<el-form-item label-width="100px" :label="st.title" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+si+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="st.is_input === 1" style="margin:20px 10px 22px 10px;">
										<el-input v-model="formData.content[index].input[si].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit @input="setCache"></el-input>
									</el-form-item>
								</template>
							</el-form-item>
							<el-form-item :prop="'content.'+index+'.value'" v-else-if="item.type === 'percent'" :show-message="true">
								<div style="margin:0 10px">占比总和：{{getTotalPercent(index)}}</div>
								<template style="border:1px solid #eee;margin-bottom:10px;" v-for="(st,si) in item.input">
									<el-form-item label-width="0px" label="" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+si+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" style="margin:20px 10px 22px 10px;">
										<div>{{st.title}}</div>
										<div class="flex flex-ai">
											<div style="width: 40px;background-color: #eee;" class="text-center f16">{{formData.content[index].input[si].value}}</div>
											<el-slider v-model="formData.content[index].input[si].value" class="flex1" style="margin-left: 30px;" :marks="{0:'0',100:'100'}" @input="(e)=>onChangeSlider(index,si,st,e)" @mousedown.native="e=>onMouseDown(index,si,e)"></el-slider>
										</div>
									</el-form-item>
								</template>
							</el-form-item>
							<el-form-item :show-message="true" :prop="'content.'+index+'.value'" v-else-if="item.type === 'table-input'">
								<template v-if="item.input && Object.keys(item.input).length > 0">
<!--									<div class="flex flex-ai margin-bottom" v-for="(st,si) in item.child">-->
<!--										{{st.title}}-->
<!--										<el-form-item :show-message="false" style="margin:0 10px 10px;margin-bottom:0px;" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+st.id+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="st.is_input === 1">-->
<!--											<el-input v-model="formData.content[index].input[st.id].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit @input="setCache"></el-input>-->
<!--										</el-form-item>-->
<!--									</div>-->
									<div class="el-table el-table--border margin-top">
										<div class="el-table__body-wrapper">
											<table class="el-table__body" cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
												<tbody>
												<tr class="el-table__row">
													<td class="el-table__cell" v-for="(st,si) in item.input" style="text-align: center;font-weight: bold;">{{st.title}}</td>
												</tr>
												<tr class="el-table__row">
													<td class="el-table__cell" v-for="(st,si) in item.input" style="text-align: center;font-weight: bold;">
														<el-form-item style="margin:0 10px 10px;margin-bottom:22px;" :required="st.is_required === 1" :prop="'content.'+index+'.input.'+si+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="st.is_input === 1">
															<el-input v-model="formData.content[index].input[si].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit @input="setCache"></el-input>
														</el-form-item>
													</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</template>
							</el-form-item>
							<el-form-item :show-message="true" :prop="'content.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请上传文件'}]" v-else-if="item.type === 'files'">
								<file-upload v-model="formData.content[index].value" :files="formData.content[index].files" @change="setCache" @input="(e)=>onUploadFiles(e,item,index)"></file-upload>
							</el-form-item>
              <el-form-item :show-message="true" :prop="'content.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请输入内容'}]" v-else>
                <el-input class="margin-top" v-model="formData.content[index].value" :placeholder="'请输入内容'+(item.q_required === 1 ? '(必填)' : '')" maxlength="100" show-word-limit @input="setCache"></el-input>
              </el-form-item>
	            <el-form-item label="上传附件(可选)" v-if="item.is_files === 1">
		            <file-upload v-model="formData.content[index].files_ids" :files="formData.content[index].files" @change="setCache" @input="(e)=>onUploadFiles(e,item,index)"></file-upload>
<!--		            <image-upload v-model="formData.content[index].files_ids"></image-upload>-->
	            </el-form-item>
            </div>
          </el-form>
          <div class="text-center">
            <el-button type="info" @click="toPre">上一步</el-button>
            <el-button type="warning" @click="submit(0)" v-if="formData.check_state === 0">暂存</el-button>
            <el-button type="warning" @click="submit(3)" v-if="formData.check_state === 3">暂存</el-button>
            <el-button type="success" class="box-shadow-green" @click="submit(1)" v-if="formData.check_state === 0 || formData.check_state === 3">提交审核</el-button>
          </div>
        </template>
        <div class="box-shadow bg-light green padding-20 text-center border-radius-5 margin-auto margin-top-30 margin-bottom-30" style="width: 500px;" v-else><i class="orange margin-right el-icon-warning"></i>你无权查看该信息，请登录<login-button></login-button></div>
      </div>
    </div>
		<div style="width: 102px;position: fixed;right:20px;top:300px;border:1px solid #eee;" class="bg-light" v-if="info && info.qrcode">
			<img :src="info.qrcode" style="width: 100px;"/>
			<div class="text-center f14" style="line-height: 30px;">扫码分享</div>
		</div>
  </lee-page>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      id:'',
      info:null,
      list:[],
      formData:{
				id:'',
				tid:'',
				company_id:'',
				reason:'',
        content:[],
	      start_time:0,
	      check_state:0,
      },
      jumpList:[],
			active:2
    }
  },
  created() {
    this.formData.tid = this.$route.params.id ? this.$route.params.id : '';
    this.formData.company_id = this.$route.params.company_id ? this.$route.params.company_id : '';
  },
  mounted() {
    this.getDetail();
    this.getList();
  },
  methods: {
		toPre(){
			this.$router.back();
		},
		onMouseDown(index,si,e){
			let _old = null;
			// document.onmousemove = (event)=>{
			// 	console.log('==onmousemove==')
			// 	let _total = this.getTotalPercent(index);
			// 	let _current = this.formData.content[index].input[si].value;
			// 	let _max = 100 - (_total - _current);
			// 	console.log(_total,'_total');
			// 	console.log(_current,'_current');
			// 	console.log(_max,'_max');
			// 	console.log(event);
			// 	if(_current > _max){
			// 		_old = _current;
			// 		this.formData.content[index].input[si].value = _max;
			// 		event.stopPropagation();
			// 	}else{
			// 		// event.s
			// 	}
			// }
			// document.onmouseup = (event)=>{
			// 	document.onmousemove = null;
			// 	document.onmouseup = null;
			// }
		},
		onChangeSlider(i,j,t,e){
			// console.log(i,j,t,e);
			let _total = this.getTotalPercent(i);
			let _diff = 0;
			let _arr = [];
			if(_total > 100){
				_diff = _total - 100;
			}

			if(_diff > 0) {
				for (let n in this.formData.content[i].input) {
					if (n !== j) {
						_arr.push(this.formData.content[i].input[n]);
					}
				}
				let maxKey = Object.keys(_arr).reduce((a, b) => _arr[a].value > _arr[b].value ? a : b); // 找到最大值的键
				let maxValue = _arr[maxKey]; // 使用找到的键来获取最大值

				this.formData.content[i].input[_arr[maxKey].id].value = this.formData.content[i].input[_arr[maxKey].id].value - _diff;
			}
		},
		// getMaxPercent(index,si){
		// 	let _total = this.getTotalPercent(index);
		// 	return 100 - _total;
		// },
		delCache(){
			this.$utils.localDel('survey_'+this.id);
		},
		setCache(){
			// this.$utils.localSet('survey_'+this.id,JSON.stringify(this.formData.content));
		},
		getCache(){
			let _list = this.$utils.localGet('survey_'+this.id);

			if(_list){
				this.formData.content = JSON.parse(_list);
				return true;
			}
			return false;
		},
		setFilesCache(e){
			let _list = [];

			for(let i=0;i < e.length;i++){
				if(e[i].response.result){
					_list.push(e[i].response.result);
				}else{
					_list.push(e[i]);
				}
			}
			this.$utils.localSet('survey_files_'+this.id,JSON.stringify(_list));
		},
		getFilesCache(){
			let _list = this.$utils.localGet('survey_files_'+this.id);

			if(_list){
				return JSON.parse(_list);
			}
			return false;
		},
		onUploadFiles(e,item,index){
			let _list = [];
			for(let i=0;i < e.length;i++){
				if(e[i].response && e[i].response.result){
					_list.push(e[i].response.result);
				}else{
					_list.push(e[i]);
				}
			}
			_list.forEach((t,i)=>{
				delete _list[i].base64
			})
			this.formData.content[index]['files'] = _list;
		},
    resetJumpValue(){
      // console.log(this.jumpList);
      if(this.jumpList.length > 0){
        for(let i=0;i < this.formData.content.length;i++){
          if(this.jumpList.indexOf(this.formData.content[i].id) < 0 && this.formData.content[i].is_jump === 1){

            this.formData.content[i].value = [];

            for(let j in this.formData.content[i].input){
              this.formData.content[i].input[j].value = '';
            }
          }
        }
      }
    },

    cleanJump(question){
      if(question){
        if(question.jumpid && question.is_jump === 0){
          this.jumpList.splice(this.jumpList.indexOf(question.jumpid), 1);
        }else {
          if (question.child.length > 0) {
            for (let i = 0; i < question.child.length; i++) {
              if (this.jumpList.indexOf(question.child[i].jumpid) >= 0) {
                this.jumpList.splice(this.jumpList.indexOf(question.child[i].jumpid), 1);
              }

              for (let j = 0; j < this.list.length; j++) {
                if (this.list[j].id === question.child[i].jumpid && this.list[j].is_jump === 1) {
                  this.cleanJump(this.list[j]);
                }
              }
            }
          }
        }
      }
    },

    onChangeRadio(e,qi,ai,qindex,aindex){
      if(qi.type === 'radio'){
        if(qi.jump_type === 1){
          for (let i=0;i < qi.child.length;i++){
            if(qi.child[i].jumpid && qi.child[i].jumpid !== '0'){
              let _index = this.jumpList.indexOf(qi.child[i].jumpid);
              if(_index >= 0){
                this.jumpList.splice(_index,1);
              }
            }
          }
          this.jumpList.push(ai.jumpid)
        }else{
          if (qi.jumpid && qi.jumpid !== '0' && this.jumpList.indexOf(qi.jumpid) < 0) {
            this.jumpList.push(qi.jumpid);
          }
        }

        for (let j in this.formData.content[qindex].input){
          if(j !== ai.id){
            this.formData.content[qindex].input[j].value = '';
          }
        }

      }else if(qi.type === 'multi'){
        if(e){
          if (qi.jump_type === 2) {
            if (qi.jumpid && qi.jumpid !== '0' && this.jumpList.indexOf(qi.jumpid) < 0) {
              this.jumpList.push(qi.jumpid);
            }
          } else if (qi.jump_type === 1) {
            if (ai.jumpid && ai.jumpid !== '0' && this.jumpList.indexOf(ai.jumpid) < 0) {
              this.jumpList.push(ai.jumpid);
            }
          }
        }else{
          if (qi.jump_type === 2) {
            if (qi.jumpid && qi.jumpid !== '0' && this.jumpList.indexOf(qi.jumpid) >= 0) {
              this.jumpList.splice(this.jumpList.indexOf(qi.jumpid),1);
            }
          } else if (qi.jump_type === 1) {
            if (ai.jumpid && ai.jumpid !== '0' && this.jumpList.indexOf(ai.jumpid) >= 0) {
              this.jumpList.splice(this.jumpList.indexOf(ai.jumpid),1);
            }
          }

          if(this.formData.content[qindex].input[ai.id]){
            this.formData.content[qindex].input[ai.id].value = '';
          }

          console.log(ai);
        }
      }

      this.resetJumpValue();
    },
    submit(state){
      let _this = this;

			if(state === 0 || state === 3){
				_this.$http.post({
					url:'/SurveyLog/add',
					data:{...this.formData,check_state:state,content:JSON.stringify(this.formData.content),platform:'pc'},
					success(res){
						_this.$message.success('保存成功');
					}
				})
			}else{
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.$confirm('是否确定要提交审核?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$http.post({
								url:'/SurveyLog/add',
								data:{..._this.formData,check_state:state,content:JSON.stringify(_this.formData.content),platform:'pc'},
								success(res){
									// console.log(res);
									// _this.$alert('提交成功，返回', '提示', {
									//   confirmButtonText: '立即返回',
									//   showClose:false,
									//   callback: action => {
									//     _this.$router.back();
									//   }
									// });
									// _this.delCache();
									_this.$router.replace({path:'/survey/done'});
								}
							})
						}).catch(() => {});
					} else {
						_this.$message.error('奖项申报请填写完整');
						return false;
					}
				});
			}
    },
    getDetail(){
      let _this = this;

      this.$http.post({
        url:'/SurveyTitle/detail',
        data:{id:this.formData.tid},
        success(res){

          if(res.state === 0 || res.state === 2){
            _this.$alert('此奖项申报已关闭 或 未开启', '提示', {
              confirmButtonText: '立即返回',
              showClose:false,
              callback: action => {
                _this.$router.back();
              }
            });
            return;
          }

          _this.info = res;
					_this.formData.start_time = _this.$moment().unix();
        },
        fail(err){
          if(err.code === -100){
            _this.$alert(err.msg, '提示', {
              confirmButtonText: '立即返回',
              showClose:false,
              callback: action => {
                _this.$router.back();
              }
            });
          }else{
            _this.$message.error(err.msg);
          }
        }
      })
    },

    getList(){
      ///SurveyCol/listDigui
      let _this = this;

      this.$http.post({
        url:'/SurveyCol/listDigui',
        data:{id:this.formData.tid,company_id:this.formData.company_id},
        success(res){
          // console.log(res);
					_this.formData.id = res.id;
					_this.formData.content = res.content;
					_this.formData.check_state = res.check_state;
					_this.formData.reason = res.reason;
        }
      })
    },

		getTotalPercent(i){
			let _data = this.formData.content[i].input;
			let _res = 0;
			for (let j in _data){
				_res += _data[j].value;
			}
			return _res;
		}
  },
  computed:{
    // getIndex(){
    //   this.$nextTick(()=>{
    //     let _cols = this.$refs['collist'];
    //     console.log(_cols);
    //     return _cols.length;
    //   })
    // }
  }
}
</script>
