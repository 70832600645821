<template>
	<div class="page rel" :style="{backgroundImage:'url('+(absBgImg ? absBgImg : bgImg)+')',height:height ? height +'px' : '528px'}" style="min-width: 1300px;">
		<slot name="top"></slot>
		<template v-if="isMobile">
			<div style="padding: 10px;" id="nav" :style="{backgroundColor:'rgba(255,255,255,'+op.op+')'}" :class="op.class">
				<el-button icon="el-icon-more" size="mini" @click="drawer = true"></el-button>
			</div>
			<el-drawer
				:withHeader="false"
				size="70%"
				append-to-body
				:visible.sync="drawer"
				direction="ltr">
				<ul class="cl head2">
					<li class="logo padding-left"><img :src="'/images/logo_dark.png'" height="40" class="margin-top-5"/></li>
					<li class="f18 light" style="line-height:62px;width: auto !important;" v-if="isLogin">
						<a href="javascript:void(0);" @click="$router.push({path:'/user'})" class="text-line-1">
							<div class="text-line-1">您好，{{ $user.getNickName() ? $user.getNickName() : '立即完善资料' }}</div>
						</a>
					</li>
					<li v-for="(item,index) in menuList">
						<a href="javascript:void(0);" :class="item.avalue ==='/' ? ($route.path === item.avalue ? 'on' : '') : $route.path.indexOf(item.avalue) === 0 ? 'on' : ''" @click="$router.push({path:item.avalue})">{{ item.name }}</a>
					</li>
					<li class="padding-left" style="line-height:62px;width: auto !important;" v-if="!isLogin">
						<el-button type="success" size="small" class="vm" @click="$router.push({path:'/login'})">登录</el-button>
						<el-button size="small" type="success" plain class="vm" @click="$router.push({path:'/register'})">注册会员
						</el-button>
					</li>
					<li class="padding-left" style="line-height:62px;width: auto !important;" v-if="isLogin">
						<el-button type="success" size="small" class="vm" @click="logout">退出</el-button>
					</li>
<!--					<li class="y flex-ai flex-jc hand" style="display:flex !important;height:62px;margin-right: 5px !important;" @click="$router.push({path:'/user/company'})" v-if="isLogin">-->
<!--						<div :class="company && company.is_check === 1 ? 'bg-brand-0 light' : 'bg-gray-3 dark-3'" class="flex flex-ai flex-jc" style="width: 24px;height: 24px;border-radius: 999px;">-->
<!--							<i class="iconfont icon-qi f24"></i>-->
<!--						</div>-->
<!--					</li>-->
<!--					<li class="y flex-ai flex-jc hand" style="display:flex !important;height:62px;margin-right: 5px !important;" @click="$router.push({path:'/user/vip'})" v-if="isLogin">-->
<!--						<div :class="vip ? 'bg-orange light' : 'bg-gray-3 dark-3'" class="flex flex-ai flex-jc" style="width: 24px;height: 24px;border-radius: 999px;">-->
<!--							<i class="iconfont icon-vip1 f16"></i>-->
<!--						</div>-->
<!--					</li>-->
				</ul>
			</el-drawer>
		</template>
		<template v-else>
			<div id="nav" :style="{backgroundColor:'rgba(255,255,255,'+op.op+')'}" :class="op.class">
				<div class="margin-auto" style="width: 1200px;">
					<ul class="cl head">
						<li class="logo"><img :src="'/images/'+op.logo" height="40" class="margin-top-5"/></li>
						<li v-for="(item,index) in menuList">
							<a href="javascript:void(0);" :class="item.avalue ==='/' ? ($route.path === item.avalue ? 'on' : '') : $route.path.indexOf(item.avalue) === 0 ? 'on' : ''" @click="$router.push({path:item.avalue})">{{ item.name }}</a>
						</li>
						<li class="y" style="line-height:62px;width: auto !important;" v-if="!isLogin">
							<el-button type="success" size="mini" class="vm" @click="$router.push({path:'/login'})">登录</el-button>
						</li>
						<li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">
							<el-button type="success" size="mini" class="vm" @click="logout">退出</el-button>
						</li>
						<li class="y f18 light" style="line-height:62px;width: auto !important;" v-if="isLogin">
							<a href="javascript:void(0);" @click="$router.push({path:'/user'})" class="text-line-1">
								<div class="text-line-1">{{ $user.getNickName() ? $user.getNickName() : '立即完善资料' }}</div>
							</a>
						</li>
						<!--						<li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">-->
						<!--							<a @click="$router.push({path:'/user'})">个人信息</a>-->
						<!--						</li>-->
						<!--                        <li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">-->
						<!--                            <a @click="$router.push({path:'/user/vip'})">续费VIP</a>-->
						<!--                        </li>-->
						<li class="y" style="line-height:62px;width: auto !important;" v-if="!isLogin">
							<el-button size="mini" type="success" plain class="vm" @click="$router.push({path:'/register'})">注册会员
							</el-button>
						</li>
						<li class="y flex-ai flex-jc hand" style="display:flex !important;height:62px;margin-right: 5px !important;" @click="$router.push({path:'/user/company'})" v-if="isLogin">
							<div :class="company && company.is_check === 1 ? 'bg-brand-0 light' : 'bg-gray-3 dark-3'" class="flex flex-ai flex-jc" style="width: 24px;height: 24px;border-radius: 999px;">
								<i class="iconfont icon-qi f24"></i>
							</div>
						</li>
						<li class="y flex-ai flex-jc hand" style="display:flex !important;height:62px;margin-right: 5px !important;" @click="$router.push({path:'/user/vip'})" v-if="isLogin">
							<div :class="vip ? 'bg-orange light' : 'bg-gray-3 dark-3'" class="flex flex-ai flex-jc" style="width: 24px;height: 24px;border-radius: 999px;">
								<i class="iconfont icon-vip1 f16"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</template>
		<div class="cl"></div>
		<div class="rel" style="z-index: 2;">
			<div class="text-left wrap1100 text-line-2" :class="[search ? (this.titleClass ? 'light f48 '+this.titleClass : 'margin-top-50 f48 light') : (this.titleClass ? 'light f48 '+this.titleClass : 'margin-top-70 f48 light'),titlePos === 'center' ? 'text-center' : 'text-left']" v-if="absTitle" v-html="absTitle">
				{{ absTitle }}
			</div>
			<template v-else>
				<div class="text-left wrap1100 text-line-2" :class="[search ? (this.titleClass ? 'light f48 '+this.titleClass : 'margin-top-50 f48 light') : (this.titleClass ? 'light f48 '+this.titleClass : 'margin-top-70 f48 light'),titlePos === 'center' ? 'text-center' : 'text-left']" v-if="title" v-html="title">
					{{ title }}
				</div>
				<div class="wrap1100" :class="[titlePos === 'center' ? 'text-center' : 'text-left',subTitleClass ? subTitleClass : 'light f-thin margin-top f22']" :style="subTitleStyle" v-if="subTitle">{{ subTitle }}</div>
				<div class="wrap1100 margin-auto text-line-1" :class="[titlePos === 'center' ? 'text-center' : 'text-left',desClass ? desClass : 'f20 light margin-top-20']" :style="desStyle" v-html="des" v-if="des"></div>
			</template>
			<div class="margin-top-20 margin-auto" style="width: 866px" v-if="!$slots.search && search">
				<lee-search :searchPath="searchPath" :showPolicy="showPolicy" :showMmType="showMmType" :searchSlugs="searchSlugs" :stopSearch="stopSearch" :toPath="toPath" :showYear="showYear" @search="onSearch" :search-time-key="searchTimeKey"></lee-search>
			</div>
		</div>
		<slot name="search"></slot>
	</div>
</template>

<script>
import Utils from '../script/utils'

export default {
	name: "PageHeader",
	data() {
		return {
			scrollTop:0,
			isLogin: false,
			company:null,
			vip:null,
			menuList:[],
			bgImg:'',
			title:'',
			subTitle:'',
			des:'',
			titlePos:'',
			isMobile:false,
			drawer:false
		}
	},
	props:{
		absBgImg: {
			type: String,
			default: ''
		},
		absTitle: {
			type: String,
			default: ''
		},
		absSubTitle: {
			type: String,
			default: ''
		},
		absDes: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'light'
		},
		code:{
			type:String,
			default:''
		},
		height: {
			type: [String, Number],
			default: ''
		},
		titleClass: {
			type: String,
			default: ''
		},
		subTitleClass: {
			type: String,
			default: ''
		},
		desClass: {
			type: String,
			default: ''
		},
		titleStyle: {
			type: String,
			default: ''
		},
		subTitleStyle: {
			type: String,
			default: ''
		},
		desStyle: {
			type: String,
			default: ''
		},
		search: {
			type: Boolean,
			default: false
		},
		searchPath: {
			type: String,
			default: '',
		},
		searchSlugs: {
			type: Array,
			default() {
				return []
			}
		},
		stopSearch: {
			type: Boolean,
			default: false
		},
		showYear: {
			type: Boolean,
			default: false
		},
		showPolicy: {
			type: Boolean,
			default: false
		},
		showMmType: {
			type: Boolean,
			default: false,
		},
		toPath: {
			type: String,
			default: '',
		},
		searchTimeKey: {
			type: String,
			default: 'create_time'
		}
	},
	created() {
		this.getMenu()
	},
	mounted() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
		this.isLogin = !!this.$user.getToken();
		if(this.isLogin){
			this.getUserInfo();
		}
		this.getDetail();
		// this.isMobile = this.$utils.isMobile().versions.mobile;
	},
	computed: {
		op() {
			let _op = this.type === 'light' ? this.scrollTop / 50 : 1;

			return _op >= 1 ? {op: 1, class: 'nav-dark', logo: 'logo_dark.png', color: '#333'} : {op: _op, class: '', logo: 'logo.png', color: '#fff'};
		},
	},
	methods: {
		getUserInfo(){
			let _this = this;
			this.$user.getUserInfo(()=>{
				_this.vip = _this.$user.getVip();
				_this.company = _this.$user.getCompany();
			})
		},
		getDetail(){
			let _this = this;
			if(!this.code){
				return;
			}
			this.$http.post({
				url:'/Type/detail',
				data:{code:this.code,akey:'menu'},
				success(res){
					if(res){
						_this.bgImg = _this.$utils.formatImagePath(res.head_img);
						_this.title = res.title;
						_this.subTitle = res.sub_title;
						_this.des = res.des;
						_this.titlePos = res.align;
					}
				}
			})
		},
		getMenu(){
			let _this = this;
			this.$http.post({
				url:'/Type/listAll',
				data:{akey:'menu',state:1,orderBy:'sort',sortBy:'asc'},
				success(res){
					_this.menuList = res;
				}
			})
		},
		logout() {
			this.$user.logout();
			this.$message.success('你已退出登录');
			location.reload();
		},
		onSearch(res) {
			this.$emit('onSearch', res);
		},
		handleScroll(e) {
			this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		},
	}
}
</script>
<style scoped>
.head2 li{}
.head2 li a{
	color: #333;
	font-size: 16px;
	display: block;
	padding: 0 13px;
	line-height: 52px;
	height: 52px;
}
.head2 li a.on{background-color: #7ED321;color:#fff !important;}
.head {
	list-style: none;
	height: 52px;
	overflow: hidden;
}

.head li {
	float: left;
	margin-right: 20px;
	display: inline-block;
}

.head li.logo {
	width: 40px;
}

.head li a {
	color: #fff;
	font-size: 16px;
	display: block;
	padding: 0 13px;
	line-height: 62px;
	height: 52px;
	text-align: center;
}

.head li a.on {
	background: #7ED321;
}

.nav-dark .head li a {
	color: #333 !important;
}

.nav-dark .head li a.on {
	color: #fff !important;
}
</style>
