<template>
	<lee-page code="survey" bg-img="/images/bg_head_9.jpg" title="奖项申报" height="250" :bread="[{title:'奖项申报',url:'/survey'},{title:'提交完成'}]">
		<div class="bg-img-11"  style="background-color:#f8f9fa">
			<div class="wrap1100 padding-top-bottom-100 ">
				<div class="cl"></div>
				<div class="bg-light box-shadow border-radius-5 padding-top-bottom-100 text-center">
					<i class="el-icon-success green" style="font-size: 60px;"></i>
					<div class="f16 margin-top-20 dark-2">感谢您的参与</div>
					<div class="f16 dark-2">现在将返回投票列表</div>
					<el-button type="success" class="margin-top-20" @click="goBack">立即返回</el-button>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "done",
	data() {
		return {}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		goBack(){
			this.$router.replace({path:'/survey'});
		}
	}
}
</script>
