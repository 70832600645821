<template>
	<lee-page code="hydata" type="light" title-class="margin-top-100" height="250" absTitle="宏观冷链相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'宏观冷链'}]">
		<div class="bg-img-2  padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_chain">
				<div class="wrap1100 padding-bottom-100">
<!--					<lee-year-tab :list="yearList"></lee-year-tab>-->
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<div class="green-line">
							<div class="f34 flex flex-ai flex-jc-between">
								<div class="flex flex-ai">
									<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
									<div class="f22">宏观冷链相关数据</div>
								</div>
								<div class="flex flex-ai">
									<el-select v-model="searchData" @change="onChangeYear">
										<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
									</el-select>
									<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
										<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
									</el-select>
								</div>
							</div>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">冷链物流总额</span>
							</div>
							<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1">
								{{data.now.content1}}
							</div>
							<el-row :gutter="10">
								<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar1"
											ref="bar1"
											:grid-bottom="30"
											:grid-top="30"
											right-name="同比"
											height="250px"
											show-legend
											left-name="（万亿元）"
										></lee-bar-echarts>
									</div>
								</el-col>
								<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar1_1"
											ref="bar1_1"
											:grid-bottom="30"
											:grid-top="30"
											height="250px"
											show-legend
											left-name="（万亿元）"
										></lee-bar-echarts>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">冷链物流需求总量</span>
							</div>
							<div class="margin-top-20" v-if="data && data.now && data.now.content2" v-html="data.now.content2">
								{{data.now.content2}}
							</div>
							<el-row :gutter="10">
								<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar2"
											ref="bar2"
											:grid-bottom="30"
											:grid-top="30"
											left-name="（亿吨）"
											right-name="同比"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</el-col>
								<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar2_2"
											ref="bar2_2"
											:grid-bottom="30"
											:grid-top="30"
											left-name="（亿吨）"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">冷链物流总收入</span>
							</div>
							<div class="margin-top-20" v-if="data && data.now && data.now.content3" v-html="data.now.content3">
								{{data.now.content3}}
							</div>
							<el-row :gutter="10">
								<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar3"
											ref="bar3"
											:grid-bottom="30"
											:grid-top="30"
											left-name="（亿元）"
											right-name="同比"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</el-col>
								<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar3_3"
											ref="bar3_3"
											:grid-bottom="30"
											:grid-top="30"
											left-name="（亿元）"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</el-col>
							</el-row>
							<template  v-if="!searchQuarterData || searchQuarterData === '0'">
								<div class="margin-top-20">
									<div style="border-bottom: 2px solid #7ED321;">
										<span class="light bg-green" style="padding:7px 20px;display: inline-block;">低温处理率</span>
									</div>
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="diwen"
											ref="diwen"
											:grid-bottom="30"
											:grid-top="30"
											:grid-left="50"
											left-name="低温处理率（%）"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</div>
							</template>
							<template  v-if="!searchQuarterData || searchQuarterData === '0'">
								<div style="border-bottom: 2px solid #7ED321;">
									<span class="light bg-green" style="padding:7px 20px;display: inline-block;">细分品类冷链物流需求占比</span>
								</div>
								<div class="margin-top-20" v-if="data && data.now && data.now.content4" v-html="data.now.content4">
									{{data.now.content4}}
								</div>
								<div class="margin-auto" :style="{width:'600px'}">
									<lee-pie-echarts
										ref="pie1"
										key="pie1"
										:grid-bottom="30"
										height="350px"
										:label-format="'{b}\n{d}%'"
										label-pos="outside"
										legend-orient="vertical"
										legend-pos="right"
										:radius="['0%','60%']"></lee-pie-echarts>
								</div>
							</template>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
		let _this = this;
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			console.log(e);
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLenglian/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLenglian/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLenglian/getData',
				data: _where,
				success(res) {
					console.log(res);
					// return;
					_this.data = res;

					let _bar1data = {bar: [], line: [], xaxis: []};
					let _bar1data_1 = {bar: [], xaxis: []};
					let _bar2data = {bar: [], line: [], xaxis: []};
					let _bar2data_2 = {bar: [], xaxis: []};
					let _bar3data = {bar: [], line: [], xaxis: []};
					let _bar3data_3 = {bar: [], xaxis: []};
					let _linediwen = {line: [], xaxis: []};
					//
					// let _hebing = {bar1: [], bar2: [], xaxis: []};
					// let _bar10 = {bar: [], line: [], xaxis: []};
					//
					for (let i = 0; i < res.list.length; i++) {
						_bar1data.bar.push(res.list[i].lenglian_amount);
						_bar1data.line.push(res.list[i].lenglian_rate);
						_bar1data.xaxis.push(res.list[i].time_str);

						_bar2data.bar.push(res.list[i].wlsc_amount);
						_bar2data.line.push(res.list[i].wlsc_rate);
						_bar2data.xaxis.push(res.list[i].time_str);

						_bar3data.bar.push(res.list[i].shehui_amount);
						_bar3data.line.push(res.list[i].shehui_rate);
						_bar3data.xaxis.push(res.list[i].time_str);

						_linediwen.line.push(res.list[i].diwen);
						_linediwen.xaxis.push(res.list[i].time_str);
					}

					for (let i = 0; i < res.listAll.length; i++) {
						_bar1data_1.bar.push(res.listAll[i].lenglian_amount);
						_bar1data_1.xaxis.push(res.listAll[i].time_str);

						_bar2data_2.bar.push(res.listAll[i].wlsc_amount);
						_bar2data_2.xaxis.push(res.listAll[i].time_str);

						_bar3data_3.bar.push(res.listAll[i].shehui_amount);
						_bar3data_3.xaxis.push(res.listAll[i].time_str);
					}

					setTimeout(()=>{
						if(!!_this.searchQuarterData) {
							if (_this.$refs.bar1_1) {
								_this.$refs.bar1_1.initData(
									[
										{name: '冷链物流总额', type: 'bar', color: 'blue', data: _bar1data_1.bar, dw: '万亿元'},
									],
									_bar1data_1.xaxis
								);
							}

							if (_this.$refs.bar2_2) {
								_this.$refs.bar2_2.initData(
									[
										{name: '冷链物流总需求', type: 'bar', color: 'blue', data: _bar2data_2.bar, dw: '亿吨'},
									],
									_bar2data_2.xaxis
								);
							}

							if (_this.$refs.bar3_3) {
								_this.$refs.bar3_3.initData(
									[
										{name: '冷链物流总收入', type: 'bar', color: 'blue', data: _bar3data_3.bar, dw: '亿元'},
									],
									_bar3data_3.xaxis
								);
							}
						}

						if (_this.$refs.diwen) {
							_this.$refs.diwen.initData(
								[
									{name: '低温处理率', type: 'line', color: 'purple', data: _linediwen.line, dw: '%'},
								],
								_linediwen.xaxis
							);
						}

						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷链物流总额', type: 'bar', color: 'green', data: _bar1data.bar, dw: '万亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bar1data.line, dw: '%'},
								],
								_bar1data.xaxis
							);
						}

						if (_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '冷链物流总需求', type: 'bar', color: 'green', data: _bar2data.bar, dw: '亿吨'},
									{name: '同比', type: 'line', color: 'purple', data: _bar2data.line, dw: '%'},
								],
								_bar2data.xaxis
							);
						}

						if (_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '冷链物流总收入', type: 'bar', color: 'green', data: _bar3data.bar, dw: '亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bar3data.line, dw: '%'},
								],
								_bar3data.xaxis
							);
						}

						if(!_this.searchQuarterData || _this.searchQuarterData === '0') {
							let _now = res.list[res.list.length - 1];
							let _pie1Data = [];

							_pie1Data.push({value: parseFloat(_now.sudongshipin), name: '速冻食品'});
							_pie1Data.push({value: parseFloat(_now.ruzhipin), name: '乳制品'});
							_pie1Data.push({value: parseFloat(_now.shuichanpin), name: '水产品'});
							_pie1Data.push({value: parseFloat(_now.rouzhipin), name: '肉制品'});
							_pie1Data.push({value: parseFloat(_now.shuiguo), name: '水果'});
							_pie1Data.push({value: parseFloat(_now.shucai), name: '蔬菜'});

							if (_this.$refs.pie1) {
								_this.$refs.pie1.initData(_pie1Data);
							}
						}
					},300)
				}
			})
		},
	}
}
</script>
