<template>
	<lee-page code="prize" bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" :back="false" abs-title="项目申报" :bread="[{title:'申报',url:'/prize'},{title:'项目申报'}]" v-if="info">
		<div class="bg-img-11 padding-top-100 ">
			<div class="wrap860 padding-bottom-100">
				<div class="box-shadow bg-light padding-30" v-if="info">
					<div class="green-line">
						<div class="cl f34">
							<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
<!--							{{info}}-->
							您正在申报《{{ info.title }}》
						</div>
					</div>
					<template v-if="user">
						<el-form key="form1" ref="form1" :model="formData" label-width="auto" label-position="left" :rules="rules" :disabled="[1,2].indexOf(info.check_state) >= 0">
							<el-form-item label="审核状态" prop="state">
								<el-tag type="danger" effect="dark" v-if="formData.check_state === 3">审核不通过</el-tag>
								<el-tag type="success" effect="dark" v-if="formData.check_state === 2">审核通过</el-tag>
								<el-tag type="warning" effect="dark" v-if="formData.check_state === 1">待审核</el-tag>
								<el-tag type="info" effect="dark" v-if="formData.check_state === 0">待提交</el-tag>
							</el-form-item>
							<el-form-item label="原因" prop="reason" v-if="formData && formData.reason">
								{{formData.reason}}
							</el-form-item>
							<el-form-item label="企业名称" prop="title">
								<el-input v-model="formData.title" maxlength="20" show-word-limit style="width: 100%;"></el-input>
							</el-form-item>
							<el-form-item label="关键词" prop="tags">
								<el-tag :key="tag" v-for="tag in formData.tags" :closable="info.can_edit === 1" :disable-transitions="false" @close="onCloseTags(tag)" style="margin-right: 10px;">{{tag}}</el-tag>
								<template v-if="inputVisible">
									<el-input style="width: 100px;margin-right: 10px;" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
									<el-button size="small" @click="handleInputConfirm">确定</el-button>
								</template>
								<el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
							</el-form-item>
							<el-form-item label="企业法人" prop="faren">
								<el-input v-model="formData.faren" maxlength="6" show-word-limit style="width: 100%;"></el-input>
							</el-form-item>
							<el-form-item label="企业所在地" prop="city_code">
								<province-city-select v-model="formData.address_tmp" style="width: 100%;" @change="onChangeArea"></province-city-select>
							</el-form-item>
							<el-form-item label="企业性质" prop="type_nature_id">
								<type-select style="width: 100%;" v-model="formData.type_nature_id" :data="{akey:'nature',orderBy:'sort',sortBy:'asc',state:1}"></type-select>
							</el-form-item>
							<el-form-item label="企业属性" prop="type_property_id">
								<type-select style="width: 100%;" v-model="formData.type_property_id" :data="{akey:'property',orderBy:'sort',sortBy:'asc',state:1}"></type-select>
							</el-form-item>
							<el-form-item required label="主要网点">
								<el-button class="box-shadow-green margin-bottom" type="success" size="small" @click="toAddLine('main_location')">+ 新增</el-button>
								<el-form-item :rules="[{required: true,message:'主要网点不能为空'}]" :prop="'main_location.'+i" class="margin-bottom-20" v-for="(t,i) in formData.main_location">
									<el-input v-model="formData.main_location[i]" maxlength="200" show-word-limit style="width:416px;"></el-input>
									<el-button type="danger" class="margin-left" @click="toDelLine('main_location',i)">删除</el-button>
								</el-form-item>
							</el-form-item>
							<el-form-item required label="合作客户">
								<el-button class="box-shadow-green margin-bottom" type="success" size="small" @click="toAddLine('customer')">+ 新增</el-button>
								<el-form-item :rules="[{required: true,message:'合作客户不能为空'}]" :prop="'customer.'+i" class="margin-bottom-20" v-for="(t,i) in formData.customer">
									<el-input v-model="formData.customer[i]" maxlength="200" show-word-limit style="width:416px;"></el-input>
									<el-button type="danger" class="margin-left" @click="toDelLine('customer',i)">删除</el-button>
								</el-form-item>
							</el-form-item>
							<el-form-item required label="荣誉">
								<el-button class="box-shadow-green margin-bottom" type="success" size="small" @click="toAddLine('honor')">+ 新增</el-button>
								<el-form-item :rules="[{required: true,message:'荣誉不能为空'}]" :prop="'honor.'+i" class="margin-bottom-20" v-for="(t,i) in formData.honor">
									<el-input v-model="formData.honor[i]" maxlength="200" show-word-limit style="width:416px;"></el-input>
									<el-button type="danger" class="margin-left" @click="toDelLine('honor',i)">删除</el-button>
								</el-form-item>
								<!--									<el-input v-model="formData.honor" maxlength="200" show-word-limit style="width: 100%;"></el-input>-->
							</el-form-item>
							<el-form-item label="企业宣传PPT" prop="ppt">
								<file-upload v-model="formData.ppt" :files="formData.files" style="width: 100%;"></file-upload>
							</el-form-item>
						</el-form>
						<div class="text-center">
							<el-button type="info" @click="$router.back()">返回</el-button>
							<el-button type="success" class="box-shadow-green" @click="toNext" v-if="info.state >= 1 && info.check_state >= 0">{{getNextTxt}}</el-button>
						</div>
					</template>
					<div class="text-center f16 dark-3" style="margin: 100px auto;" v-else>
						<i class="orange margin-right el-icon-warning"></i>
						请先登录
						<login-button></login-button>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 102px;position: fixed;right:20px;top:300px;border:1px solid #eee;" class="bg-light" v-if="info && info.qrcode">
			<img :src="info.qrcode" style="width: 100px;"/>
			<div class="text-center f14" style="line-height: 30px;">扫码分享</div>
		</div>
	</lee-page>
</template>

<script>
import TypeSelect from '../../components/TypeSelect'
export default {
	name: "List",
	components: {TypeSelect},
	data() {
		return {
			inputVisible: false,
			inputValue: '',
			id: '',
			info: null,
			prizejson: null,
			active:1,
			step:1,
			address:'',
			formData:{
				id:'',
				title:'',
				check_state:-1,
				reason:'',
				province_code:'',
				province_name:'',
				city_code:'',
				city_name:'',
				faren:'',
				address_tmp:'',
				type_nature_id:'',
				type_property_id:'',
				main_location:[],
				customer:[],
				honor:[],
				ppt:'',
				tags:[],
				can_edit:0
			},
			formData2:{
				id:'',
				tid:'',
				company_id:'',
				params_json:[]
			},
			user:null,
			rules:{
				title:[{required:true,message:'企业名称不能为空'}],
				city_code:[{required:true,message:'企业所在地不能为空'}],
				faren:[{required:true,message:'企业法人不能为空'}],
				type_nature_id:[{required:true,message:'企业性质不能为空'}],
				type_property_id:[{required:true,message:'企业属性不能为空'}],
				// main_location:[{required:true,message:'企业主要网点不能为空'}],
				// customer:[{required:true,message:'合作客户不能为空'}],
				// honor:[{required:true,message:'荣誉不能为空'}],
				ppt:[{required:true,message:'企业宣传ppt不能为空'}],
			}
		}
	},
	created() {
		this.id = this.$route.query.id;
		this.user = this.$user.getAll();
	},
	mounted() {
		this.getDetail();
	},
	computed:{
		getNextTxt(){
			if(this.info.state === 1){
				if(this.formData.check_state === 0){
					return '继续填报'
				}else if(this.formData.check_state === 1){
					return '查看内容'
				}else if(this.formData.check_state === 2){
					return '查看内容'
				}else if(this.formData.check_state === 3){
					return '重新填报'
				}else{
					return '下一步'
				}
			}else{
				if(this.formData.check_state === 1){
					return '查看内容'
				}else if(this.formData.check_state === 2){
					return '查看内容'
				}
			}
		}
	},
	methods: {
		toAddLine(e){
			this.formData[e].push('');
		},
		toDelLine(e,i){
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.formData[e].splice(i,1);
			}).catch(() => {});
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},

		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.formData.tags.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		onCloseTags(e){
			this.formData.tags.splice(this.formData.tags.indexOf(e), 1);
		},
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/SurveyTitle/detail',
				data: {id: this.id},
				success(res) {
					_this.info = res;
					_this.getMyCompany();
				}
			});
		},
		onChangeArea(e){
			this.formData.province_code = e.provinceCode;
			this.formData.province_name = e.provinceName;
			this.formData.city_code = e.cityCode;
			this.formData.city_name = e.cityName;
		},
		toNext(){
			let _this = this;

			if(this.formData.id && [1,2].indexOf(this.formData.check_state) >= 0){
				this.$router.push({path:'/survey/detail/'+_this.id+'/'+_this.formData.id});
				return;
			}
			if(this.formData.main_location.length <= 0){
				this.$message.error('请填写主要网点')
				return;
			}

			if(this.formData.customer.length <= 0){
				this.$message.error('请填写客户')
				return;
			}

			if(this.formData.honor.length <= 0){
				this.$message.error('请填写荣誉')
				return;
			}
			this.$refs.form1.validate(valid => {
				if (valid) {
					let _url = ''
					if(_this.formData.id){
						_url = '/Company/edit'
					}else{
						_url = '/Company/add'
					}
					_this.$http.post({
						url: _url,
						data: _this.formData,
						success(res) {
							// console.log(res);
							// _this.getMyCompany();
							// _this.getPrizeJson();
							// _this.active = 2;
							// _this.step = 2;
							_this.$router.push({path:'/survey/detail/'+_this.id+'/'+res.id})
						}
					});
				}
			})
		},
		getMyCompany(){
			let _this = this;
			this.$http.post({
				url: '/Company/myCompany',
				data: {tid:this.id},
				success(res) {
					// console.log(res);
					if(res) {
						_this.formData.id = res.id;
						_this.formData.title = res.title;
						_this.formData.province_code = res.province_code;
						_this.formData.province_name = res.province_name;
						_this.formData.city_code = res.city_code;
						_this.formData.city_name = res.city_name;
						_this.formData.faren = res.faren;
						_this.formData.type_nature_id = res.type_nature_id;
						_this.formData.nature_name = res.nature_name;
						_this.formData.property_name = res.property_name;
						_this.formData.type_property_id = res.type_property_id;
						_this.formData.main_location = res.main_location;
						_this.formData.customer = res.customer;
						_this.formData.honor = res.honor;
						_this.formData.ppt = res.ppt;
						_this.formData.files = res.files;
						_this.formData.tags = res.tags;
						_this.formData.check_state = res.check_state;
						_this.formData.reason = res.reason;
						_this.formData.can_edit = res.can_edit;
						_this.formData.address_tmp = [res.province_code, res.city_code];
						// _this.getPrizeJson()
					}
				}
			});
		},
		toPre(){
			if(this.formData.main_location.length <= 0){
				this.$message.error('主要网点不能为空');
				return;
			}

			if(this.formData.customer.length <= 0){
				this.$message.error('客户不能为空');
				return;
			}

			if(this.formData.honor.length <= 0){
				this.$message.error('荣誉不能为空');
				return;
			}
			this.active = 1;
			this.step = 1;
			this.getMyCompany();
		},
		toSubmit(){
			let _this = this;
			this.$refs.form2.validate(valid => {
				if (valid) {
					_this.$http.post({
						url: _this.formData2.id ? '/PrizeCompanyItem/edit' : '/PrizeCompanyItem/add',
						data: this.formData2,
						success(res) {
							_this.$message.success('提交成功');
							_this.$router.replace({path:'/'})
						}
					});
				}
			})
		},
		getPrizeJson(){
			let _this = this;
			_this.$http.post({
				url: '/PrizeCompanyItem/detail',
				data: {tid:this.info.id,company_id:this.formData.id},
				success(res) {
					_this.prizejson = res;
					if(res) {
						_this.formData2.id = res.id;
						_this.formData2.tid = res.tid;
						_this.formData2.company_id = res.company_id;
						_this.formData2.params_json = res.params_json;
						_this.formData2.state = '0';
					}else{
						_this.formData2.tid = _this.id;
						_this.formData2.company_id = _this.formData.id;
						_this.formData2.params_json = _this.info.params_json;
					}
				}
			});
		}
	}
}
</script>
